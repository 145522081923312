import React, { useState, useEffect, useCallback } from "react";
import Layout from "../../components/Layout/layout";
import { formatPDFReport } from "../../utils/pdfFormatting";
import { formatExcelReport } from "../../utils/excelFormatting";
import { toast } from 'react-toastify';
import { participantHeaders } from "../../services/reportsServices/reportParticipantsS";
import { participantAwardsHeaders } from "../../services/reportsServices/reportParticipantAwardsS";
import { useReportData } from '../../hooks/useReportData';
import ReportActions from '../../components/ReportHelps/ReportActions'
import FilterSection  from '../../components/ReportHelps/FilterSection'
import ReportTable  from '../../components/tables/ReportsT'
import ProgressBar from '../../components/Layout/ProgrssBar';

const ParticipantsR = () => {
  const [selectedReport, setSelectedReport] = useState("");
  const [filters, setFilters] = useState({
    gender: "", religion: "", ethnicity: "", nationality: "",
    institutionName: "", paymentStatus: "", atRisk: "", marginalised: "",
    adminNumber: "", levelName: "", status: "",
    startDateRange: ["", ""], expectedEndDateRange: ["", ""]
  });

  const {
    reportData,
    filteredData,
    setFilteredData,
    uniqueOptions,
    isLoading,
    error,
    fetchData,
    progress
  } = useReportData(selectedReport);

  useEffect(() => {
    if (selectedReport) {
      fetchData();
    }
  }, [selectedReport, fetchData]);

  const handleFilterChange = useCallback((e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleDateRangeChange = useCallback((name, value, index) => {
    setFilters(prev => ({
      ...prev,
      [name]: [
        index === 0 ? value : prev[name][0],
        index === 1 ? value : prev[name][1]
      ]
    }));
  }, []);

  const clearFilters = useCallback(() => {
    setFilters({
      gender: "", religion: "", ethnicity: "", nationality: "",
      institutionName: "", paymentStatus: "", atRisk: "", marginalised: "",
      adminNumber: "", levelName: "", status: "",
      startDateRange: ["", ""], expectedEndDateRange: ["", ""]
    });
  }, []);

  useEffect(() => {
    let filtered = reportData;
    
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        if (key === "startDateRange" || key === "expectedEndDateRange") {
          const [startDate, endDate] = value;
          if (startDate && endDate) {
            filtered = filtered.filter(item => {
              const itemDate = new Date(item[key.replace("Range", "")]);
              return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
            });
          }
        } else {
          filtered = filtered.filter(item => item[key] === value);
        }
      }
    });
    
    setFilteredData(filtered);
  }, [filters, reportData, setFilteredData]);

  const handleExport = useCallback(async (format) => {
    if (filteredData.length === 0) {
      alert("No data available for export.");
      return;
    }

    const headers = selectedReport === "Participants" ? participantHeaders : participantAwardsHeaders;

    if (format === "PDF") {
      const pdfBlob = await formatPDFReport(selectedReport, filteredData, headers);
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");
    } else if (format === "Excel") {
      formatExcelReport(selectedReport, filteredData, headers);
    }
  }, [filteredData, selectedReport]);

  const handlePrint = useCallback(async () => {
    if (filteredData.length === 0) {
      alert("No data available to print.");
      return;
    }

    try {
      const headers = selectedReport === "Participants" ? participantHeaders : participantAwardsHeaders;
      const pdfBlob = await formatPDFReport(selectedReport, filteredData, headers);
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");

      const printFrame = document.createElement("iframe");
      printFrame.style.display = "none";
      document.body.appendChild(printFrame);
      printFrame.src = pdfUrl;

      printFrame.onload = () => {
        try {
          printFrame.contentWindow.print();
        } catch (error) {
          toast.error("Printing failed:", error);
          alert("Printing failed. Please try again or use the browser print function from the preview tab.");
        }

        setTimeout(() => {
          document.body.removeChild(printFrame);
          URL.revokeObjectURL(pdfUrl);
        }, 1000);
      };
    } catch (error) {
      toast.error("Error during print:", error);
      alert("An error occurred while trying to print. Please try again.");
    }
  }, [filteredData, selectedReport]);

  const headers = selectedReport === "Participants" ? participantHeaders : participantAwardsHeaders;

  return (
    <Layout>
      <div className="reports-page p-4">
        <h1 className="text-3xl font-bold mb-6">Reports</h1>

        <div className="bg-white shadow-md rounded-lg p-4 mb-6">
          <h2 className="text-xl font-bold mb-4 border-b-2 border-blue-300 pb-2">
            Available Reports
          </h2>
          <ul className="space-y-2">
            {["Participants", "ParticipantAwards"].map((report) => (
              <li key={report}>
                <button
                  onClick={() => setSelectedReport(report)}
                  className={`w-full text-left p-2 flex items-center ${
                    selectedReport === report ? "bg-gray-200" : "bg-white"
                  } hover:bg-gray-100`}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/checked.png`}
                    alt="Checkmark"
                    className={`w-6 h-6 ${selectedReport === report ? "block" : "hidden"} mr-3`}
                  />
                  <span className={`font-semibold ${
                    selectedReport === report ? "text-blue-600" : "text-gray-800"
                  }`}>
                    {report}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>

        {selectedReport && (
          <div className="bg-white shadow-md rounded-lg p-4 mb-6">
            <h2 className="text-xl font-bold mb-4">{selectedReport} Report</h2>
            {isLoading ? (
             <ProgressBar 
             progress={progress.loaded} 
             total={progress.total}
             loaded={progress.loaded}
           />
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) :
            (
              <>
                <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold mb-2 sm:mb-0">
                    Filters
                  </h3>
                  <ReportActions
                    onExportPDF={() => handleExport("PDF")}
                    onExportExcel={() => handleExport("Excel")}
                    onPrint={handlePrint}
                    onClearFilters={clearFilters}
                  />
                </div>

                <FilterSection
                  selectedReport={selectedReport}
                  filters={filters}
                  uniqueOptions={uniqueOptions}
                  handleFilterChange={handleFilterChange}
                  handleDateRangeChange={handleDateRangeChange}
                  clearFilters={clearFilters}
                />

                <div className="overflow-x-auto">
                  <h3 className="text-lg font-bold mb-2">Filtered Results</h3>
                  <p className="text-red-500 font-semibold text-2xl">
                    Total Records: {filteredData.length}
                  </p>
                  <ReportTable headers={headers} data={filteredData} />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ParticipantsR;