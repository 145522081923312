import axios from 'axios';

const getBaseURL = () => {
  const hostname = window.location.hostname;

  // Check if the hostname matches the local server's IP or domain
  if (hostname === '192.168.1.100') {
    return process.env.REACT_APP_API_LOCAL_BASE_URL; // Local IP for internal network
  }

  // Default to the public base URL
  return process.env.REACT_APP_API_PUBLIC_BASE_URL;
};

const apiClient = axios.create({
  baseURL: getBaseURL(),
  headers: {
    'Content-Type': 'application/json',
  },
});

export default apiClient;
