import React from 'react';
import { Printer, FileSpreadsheet, FileText } from 'lucide-react';
import { RefreshCw } from 'lucide-react';


const ReportActions = ({ onExportPDF, onExportExcel, onPrint, onClearFilters }) => (
  <div className="flex flex-wrap justify-center sm:justify-end gap-2">
    <button
      onClick={onClearFilters}
      className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 flex items-center"
    >
      <RefreshCw className="w-4 h-4 mr-2" /> Clear Filters
    </button>
    <button
      onClick={onExportPDF}
      className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 flex items-center"
    >
      <FileText className="w-4 h-4 mr-2" /> Export to PDF
    </button>
    <button
      onClick={onExportExcel}
      className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 flex items-center"
    >
      <FileSpreadsheet className="w-4 h-4 mr-2" /> Export to Excel
    </button>
    <button
      onClick={onPrint}
      className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 flex items-center"
    >
      <Printer className="w-4 h-4 mr-2" /> Print
    </button>
  </div>
);

export default ReportActions;