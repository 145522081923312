import React from 'react';
import FilterSelect  from './FilterSelect';
import DateRangeFilter from './DateRange';

const FilterSection = ({ selectedReport, filters, uniqueOptions, handleFilterChange, handleDateRangeChange, clearFilters }) => {
  if (selectedReport === "Participants") {
    return (
      <div className="grid grid-cols-4 gap-4 mb-4">
        {[
          "gender", "religion", "ethnicity", "nationality",
          "institutionName", "paymentStatus", "atRisk", "marginalised"
        ].map((field) => (
          <FilterSelect
            key={field}
            field={field}
            value={filters[field]}
            options={uniqueOptions[field]}
            onChange={handleFilterChange}
          />
        ))}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-4 gap-4 mb-4">
      {["adminNumber", "institutionName", "levelName", "status"].map((field) => (
        <FilterSelect
          key={field}
          field={field}
          value={filters[field]}
          options={uniqueOptions[field]}
          onChange={handleFilterChange}
        />
      ))}
      <DateRangeFilter
        label="Start Date Range"
        name="startDateRange"
        value={filters.startDateRange}
        onChange={handleDateRangeChange}
      />
      <DateRangeFilter
        label="Actual End Date Range"
        name="expectedEndDateRange"
        value={filters.expectedEndDateRange}
        onChange={handleDateRangeChange}
      />
    </div>
  );
};
export default FilterSection;