import React from 'react';

const ProgressBar = ({ progress, total, loaded }) => {
  const percentage = Math.min(100, Math.round((loaded / total) * 100));
  
  return (
    <div className="w-full p-4 bg-white rounded-lg shadow">
      <div className="flex justify-between mb-2">
        <span className="text-sm font-medium text-gray-700">Loading data...</span>
        <span className="text-sm font-medium text-gray-700">{percentage}%</span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div 
          className="bg-blue-600 h-2.5 rounded-full transition-all duration-300 ease-in-out"
          style={{ width: `${percentage}%` }}
        />
      </div>
      <div className="mt-2 text-sm text-gray-600">
        Loaded {loaded.toLocaleString()} of {total.toLocaleString()} records
      </div>
    </div>
  );
};

export default ProgressBar;