import React from 'react';

const ReportTable = React.memo(({ headers, data }) => (
    <div className="max-h-96 overflow-y-auto">
      <table className="w-full bg-white border border-gray-300">
        <thead className="sticky top-0 bg-white">
          <tr>
            {headers.map((header, index) => (
              <th key={index} className="py-2 px-4 border-b">
                {header.Header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              {headers.map((header, idx) => (
                <td key={idx} className="py-2 px-4 border-b whitespace-nowrap">
                  {item[header.accessor]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ));

  export default ReportTable