import apiClient from './apiClient';
import { logAction } from '../utils/logger';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const addawardsService = async (AwardData) => {
  try {
    const response = await apiClient.post('/api/ParticipantAward', AwardData);
    if(response){
      logAction("Created Awards","Awards",`Created the following Awards ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error('Error in addAwards:', error.response.data);
    throw error;
  }
};

const getAwardsById = async (AwardId) => {
  try {
    const response = await apiClient.get(`/api/ParticipantAward/${AwardId}`);
    return response.data;
  } catch (error) {
    toast.error(`Error fetching Participants Award with ID ${AwardId}:`, error.response.data);
    throw error;
  }
};

export const getEnrolledStats = async () => {
  try {
    const response = await apiClient.get('/api/ParticipantAward/stats');
    return response.data;
  } catch (error) {
    toast.error('Error fetching enrolled stats:', error.response?.data || error.message);
    throw error;
  }
};

const updateAwards = async (AwardId, updatedData) => {
  try {
    const response = await apiClient.put(`/api/ParticipantAward/${AwardId}`, updatedData);
    if(response){
      logAction("Updated Awards","Awards",`Updated the following Awards ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error(`Error updating Participants Award with ID ${AwardId}:`, error.response.data);
    throw error;
  }
};

const deleteAwards = async (AwardId) => {
  try {
    const response = await apiClient.delete(`/api/ParticipantAward/${AwardId}`);
    if(response){
      logAction("Deleted Awards","Awards",`Deleted the following Awards ${JSON.stringify(response.data)}`)
    }
  } catch (error) {
    toast.error(`Error deleting Participants Award with ID ${AwardId}:`, error.response.data);
    throw error;
  }
};

const getAwards = async () => {
  try {
    const response = await apiClient.get('/api/ParticipantAward');
    return response.data;
  } catch (error) {
    toast.error('Error fetching Participants Awards:', error.response.data);
    throw error;
  }
};

export { addawardsService, getAwardsById, updateAwards, deleteAwards, getAwards };
