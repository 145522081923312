import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaPlus, FaFilter } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { customStyles } from "../../styles/customStyles";
import AjApprovalForm from "../../components/forms/ajApprovalF";
import AjApprovalTable from "../../components/tables/ajApprovalT";
import AjApprovalModal from "../activities/AjApprovalModal";
import Layout from "../../components/Layout/layout";
import { getAjApproval, getAjApprovalById,} from "../../services/ajsApprovalS";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

Modal.setAppElement("#root");

// Helper function to format date to yyyy-MM-dd
const formatDate = (date) => {
  const d = new Date(date);
  return d.toISOString().split('T')[0];
};

// Helper function to validate date string
const isValidDate = (dateString) => {
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date);
};

const INITIAL_FORM_STATE = {
  awardCentre: "",
  awardLeader: "",
  region: "",
  activityName: "",
  applyDate: formatDate(new Date()), 
  activityDate: formatDate(new Date()),  
  assessors: "",
  assessors2: "",
  assessors3: "",
  participantsNo: "",
  uploadForm: null,    // Changed to lowercase to match API
  uploadForm2: null,   // Second file
  uploadForm3: null,
  approval: "pending",
  consent: false,
  notes: "",
};

const AddAjApproval = () => {
  const [approvals, setApprovals] = useState([]);
  const [filteredApprovals, setFilteredApprovals] = useState([]);
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [searchTerm, setSearchTerm] = useState("");
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [modalStates, setModalStates] = useState({
    form: false,
    approval: false
  });

  useEffect(() => {
    fetchApprovals();
  }, []);

  const fetchApprovals = async () => {
    try {
      const data = await getAjApproval();
      setApprovals(data);
      setFilteredApprovals(data);
    } catch (error) {
      toast.error("Error fetching approvals:", error);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.uploadForm) {
      newErrors.uploadForm = "The UploadForm field is required.";
    }
    
    if (!formData.activityDate) {
      newErrors.activityDate = "Activity date is required.";
    }
    
    // Add other validations as needed
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    const submitFormData = new FormData();

    // Append form data with improved multiple file handling
    Object.keys(formData).forEach(key => {
      if (key === 'approval') {
        // Explicitly set to 'pending' when creating a new form
        submitFormData.append(key, "pending");
      } else if (['uploadform', 'uploadform2', 'uploadform3'].includes(key)) {
        // Handle file upload specifically
        if (formData[key] instanceof File) {
          // If it's a new file upload
          submitFormData.append(key, formData[key], formData[key].name);
        } else if (formData[key]) {
          // If it's an existing file in edit mode
          submitFormData.append(key, formData[key]);
        }
      } else if (typeof formData[key] === 'boolean') {
        // Handle boolean values
        submitFormData.append(key, formData[key].toString());
      } else if (formData[key] !== null && formData[key] !== undefined) {
        // Handle all other values
        submitFormData.append(key, formData[key]);
      }
    });

    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/ActivityApproval/${editMode ? selectedId : ''}`;
      const method = editMode ? 'PUT' : 'POST';
      
      const response = await fetch(url, { 
        method, 
        body: submitFormData
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(JSON.stringify(errorData) || 'Failed to submit form');
      }
      
      closeModal('form');
      fetchApprovals();
      setErrors({});
    } catch (error) {
      toast.error("Form submission error:", error);
      try {
        const errorData = JSON.parse(error.message);
        setErrors(errorData.errors || {});
      } catch {
        setErrors({ general: "An error occurred while submitting the form" });
      }
    }
  };

  const handleApprovalAction = async (id, action, details = {}) => {
    try {
      const formData = new FormData();
      
        // Use string values directly
    formData.append("approval", 
      action === 'approve' ? "approved" : 
      action === 'reject' ? "rejected" : 
      "pending"
    );
      // Determine approval status
      const approvalStatus = action === 'approve' ? "true" : "false";
      formData.append("approval", approvalStatus);
      
      // Handle approval case
      if (action === 'approve') {
        if (!details.assessors) {
          toast.error("At least one assessor must be selected to approve the request.");
          return;
        }
        formData.append("assessors", details.assessors);
      }
      
      // Always append notes
      if (details.notes) {
        formData.append("notes", details.notes);
      } else {
        toast.error("Notes are required for both approval and rejection.");
        return;
      }
      
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/ActivityApproval/${id}`,
        { method: "PUT", body: formData }
      );
  
      if (!response.ok) throw new Error(await response.text());
  
      fetchApprovals();
      closeModal('approval');
    } catch (error) {
      toast.error("Approval action error:", error);
    }
  };


  const handleEdit = async (id) => {
    try {
      const data = await getAjApprovalById(id);
      setFormData({
        ...INITIAL_FORM_STATE,
        ...data,
        activityDate: data.activityDate ? new Date(data.activityDate).toISOString().split('T')[0] : '',
        consent: Boolean(data.consent),
        approval: data.approval || "pending" 
      });
      setSelectedId(id);
      setEditMode(true);
      openModal('form');
    } catch (error) {
      toast.error("Error fetching approval:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  // Improved file input handler for multiple files
const handleFileChange = (e) => {
  const { name, files } = e.target;
  const file = files[0];

  if (file) {
    // Optional: Add file validation here
    const maxSize = 5 * 1024 * 1024; // 5MB
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];
    
    if (file.size > maxSize) {
      setErrors(prev => ({
        ...prev,
        [name]: 'File size should not exceed 5MB'
      }));
      e.target.value = ''; // Reset file input
      return;
    }
    
    if (!allowedTypes.includes(file.type)) {
      setErrors(prev => ({
        ...prev,
        [name]: 'Only PDF, JPEG, and PNG files are allowed'
      }));
      e.target.value = ''; // Reset file input
      return;
    }
    setFormData(prev => ({
      ...prev,
      [name]: file
    }));
    // Clear any previous file errors
    setErrors(prev => ({
      ...prev,
      [name]: undefined
    }));
  }
};

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this approval?")) return;
    
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/ActivityApproval/${id}`, {
        method: 'DELETE'
      });
      fetchApprovals();
    } catch (error) {
      toast.error("Delete error:", error);
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    const filtered = approvals.filter(approval =>
      approval[selectedColumn]?.toString().toLowerCase().includes(value.toLowerCase())
    );
    setFilteredApprovals(filtered);
  };

  const openModal = (type) => setModalStates(prev => ({ ...prev, [type]: true }));
  const closeModal = (type) => {
    setModalStates(prev => ({ ...prev, [type]: false }));
    if (type === 'form') {
      setFormData(INITIAL_FORM_STATE);
      setEditMode(false);
      setErrors({});
    }
  };

  return (
    <Layout>
      <div className="bg-white rounded-lg shadow-lg">
        {/* Header section */}
        <div className="px-6 py-4 border-b border-gray-200">
          <h1 className="text-2xl font-semibold text-gray-800">Adventurous Journey Approval</h1>
        </div>

        <div className="p-6">
          {/* Action buttons and search section */}
          <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-6">
            <button
              onClick={() => openModal('form')}
              className="inline-flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-all"
            >
              <FaPlus className="mr-2" /> AJ Approval
            </button>

            <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
              <select
                className="flex-1 p-2 border rounded-md"
                value={selectedColumn}
                onChange={(e) => setSelectedColumn(e.target.value)}
              >
                {Object.keys(approvals[0] || {}).map(key => (
                  <option key={key} value={key}>{splitWordFunc(key)}</option>
                ))}
              </select>

              <div className="relative flex-1">
                <input
                  type="text"
                  placeholder="Search approvals..."
                  onChange={(e) => handleSearch(e.target.value)}
                  className="w-full p-2 border rounded-md pl-10"
                />
                <FiSearch className="absolute left-3 top-3 text-gray-400" />
              </div>
            </div>
          </div>

          {/* Table */}
          <AjApprovalTable
            AjApproval={filteredApprovals}
            openEditModal={handleEdit}
            deleteAjApproval={handleDelete}
            openApprovalModal={(app) => {
              setSelectedApplication(app);
              openModal('approval');
            }}
          />
        </div>

        {/* Form Modal */}
        <Modal
          isOpen={modalStates.form}
          onRequestClose={() => closeModal('form')}
          style={customStyles}
        >
          <h2 className="text-xl font-semibold mb-4">
            {editMode ? "Edit" : "Add"} Approval
          </h2>
          <AjApprovalForm
            formValues={formData}
            setFormValues={setFormData}
            handleInputChange={handleInputChange}
            handleFileChange={handleFileChange}
            handleDateChange={handleInputChange}
            errors={errors}
          />
          <div className="flex justify-end gap-2 mt-4">
            <button
              onClick={handleFormSubmit}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
            >
              {editMode ? "Update" : "Save"}
            </button>
            <button
              onClick={() => closeModal('form')}
              className="px-4 py-2 border rounded-md hover:bg-gray-100"
            >
              Cancel
            </button>
          </div>
        </Modal>

        {/* Approval Modal */}
        <AjApprovalModal
          isOpen={modalStates.approval}
          onClose={() => closeModal('approval')}
          application={selectedApplication}
          onApprove={(id, assessors) => handleApprovalAction(id, 'approve', assessors)}
          onReject={(id, details) => handleApprovalAction(id, 'reject', details)}
        />
      </div>
    </Layout>
  );
};

export default AddAjApproval;