import React from 'react';

const FilterSelect = ({ field, value, options = [], onChange }) => (
    <div>
      <label className="block text-gray-700 text-sm font-bold mb-1" htmlFor={field}>
        {field.charAt(0).toUpperCase() + field.slice(1)}
      </label>
      <select
        name={field}
        id={field}
        value={value}
        onChange={onChange}
        className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
      >
        <option value="">All</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );

  export default FilterSelect;