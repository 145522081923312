import React from 'react';

const DateRangeFilter = ({ label, name, value, onChange }) => (
    <div>
      <label className="block text-gray-700 text-sm font-bold mb-1">
        {label}
      </label>
      <div className="flex items-center">
        <input
          type="date"
          value={value[0]}
          onChange={(e) => onChange(name, e.target.value, 0)}
          className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
        />
        <span className="mx-2">to</span>
        <input
          type="date"
          value={value[1]}
          onChange={(e) => onChange(name, e.target.value, 1)}
          className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
        />
      </div>
    </div>
  );

  export default DateRangeFilter