import { useState, useEffect, useCallback } from 'react';
import { fetchParticipantData } from "../../src/services/reportsServices/reportParticipantsS";
import { fetchParticipantAwardsData } from "../../src/services/reportsServices/reportParticipantAwardsS";
import { toast } from 'react-toastify';


const BATCH_SIZE = 100; // Number of records to load in each batch
const BATCH_INTERVAL = 200; // Time between batches in milliseconds

export const useReportData = (selectedReport) => {
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueOptions, setUniqueOptions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState({ loaded: 0, total: 0 });

  const processDataInBatches = useCallback((data) => {
    return new Promise((resolve) => {
      const total = data.length;
      setProgress({ loaded: 0, total });
      
      let processedData = [];
      let currentIndex = 0;

      const processNextBatch = () => {
        const batch = data.slice(currentIndex, currentIndex + BATCH_SIZE);
        processedData = [...processedData, ...batch];
        currentIndex += BATCH_SIZE;
        
        setProgress(prev => ({
          ...prev,
          loaded: Math.min(currentIndex, total)
        }));
        
        setReportData(processedData);
        setFilteredData(processedData);

        if (currentIndex < total) {
          setTimeout(processNextBatch, BATCH_INTERVAL);
        } else {
          resolve(processedData);
        }
      };

      processNextBatch();
    });
  }, []);

  const fetchData = useCallback(async () => {
    if (!selectedReport) return;
    
    setIsLoading(true);
    setError(null);
    try {
      const data = await (selectedReport === "Participants"
        ? fetchParticipantData()
        : fetchParticipantAwardsData());
      
      await processDataInBatches(data);
      
      const options = {};
      const filterFields = selectedReport === "Participants"
        ? ["gender", "religion", "ethnicity", "nationality", "institutionName", "paymentStatus", "atRisk", "marginalised"]
        : ["adminNumber", "institutionName", "levelName", "status"];
      
      filterFields.forEach((field) => {
        let uniqueValues = [...new Set(data.map((item) => item[field]))];
        if (field === "institutionName") {
          uniqueValues = uniqueValues.filter(Boolean).sort((a, b) => (a || '').localeCompare(b || ''));
        }
        options[field] = uniqueValues;
      });
      
      setUniqueOptions(options);
    } catch (error) {
      setError("Error fetching data. Please try again.");
      toast.error(`Error fetching ${selectedReport} data:`, error);
    }
    setIsLoading(false);
  }, [selectedReport, processDataInBatches]);

  return {
    reportData,
    filteredData,
    setFilteredData,
    uniqueOptions,
    isLoading,
    error,
    fetchData,
    progress
  };
};